import { RouteObject, createBrowserRouter } from 'react-router-dom'
import dashboardRoutes from '../views/home/dashboardRoutes'
import Layout from '../components/Layout/Layout'
import Error404 from '../views/404/Error404'
import nftRoutes from '../views/nft/nftRoutes'
import adminRoutes from '../views/Admin/adminRoutes'
import adminSettingRoutes from '../views/AdminControls/adminSettingRoutes'
// import NFTRoutes from '../views/mint-nft/NFTRoutes'

const allRoutes: any = [
  ...dashboardRoutes,
  ...nftRoutes,
  ...adminRoutes,
  ...adminSettingRoutes,
  // ...NFTRoutes,
  {
    path: '*',
    element: <Layout><Error404 /></Layout>,
  },
]

export function getAllRoutes(): RouteObject[] {
  return allRoutes
}

const router = createBrowserRouter(allRoutes)

export default router
