/* eslint-disable no-unused-vars */
import React from 'react'
import styles from '../admin.module.scss'
import { CircularProgress, Grid, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { StyledButton } from '../../../components/StyledComponents/StyledComponents'
import { adminAddress } from "../../../utils/common"
import { useAccount } from 'wagmi'
import { useSelector } from 'react-redux'
import { RootStateType } from '../../../redux/interface'

function PendingCell({requestTransfer, transferRequestData, transferRequestTime, approveLoader, declineLoader}: any) {
  const theme = useTheme()
  const accountDetails = useAccount()
  const connectedAddress: any = accountDetails?.address
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))
  const validateAddress: any = useSelector((state: RootStateType) => state.auth.validatorAddress)

  return (
    <div className={styles['pending-cell']}>
      {transferRequestData
        ? <><Stack className={styles['content']}>
          <div className={styles['pending-attr']}>
            <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
              <Typography variant="body1" color="grey" fontWeight={700}> Update Validator Request</Typography>
            </Stack>
          </div>
          <Stack alignItems={breakpoint ? 'flex-start' : 'flex-end'} gap={'4px'}>
            <Typography variant='body1' fontSize={'12px'} fontWeight={700}>Address</Typography>
            <Tooltip title={transferRequestData?.newValidator} sx={{cursor: 'pointer'}}>
              <Typography variant="h5" fontWeight={700}>
                {transferRequestData?.newValidator.replace(/^(0x[a-fA-F\d]{4}).*(\w{3})$/, '$1..$2')}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack><Stack className={styles['footer']}>
          <Typography variant="body1" color="grey">Created on {transferRequestTime}</Typography>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} width={breakpoint ? '100%' : '50%'} gap={2}>
            {connectedAddress === adminAddress
              ? <StyledButton variant='text' size='small' className={styles['explore-button']} disabled>Validator Update Request Pending</StyledButton>
              : connectedAddress === validateAddress
                ? <><StyledButton variant='text' size='small' className={styles['explore-button']} onClick={() => { requestTransfer('decline') } } disabled={declineLoader || approveLoader}>
                  {declineLoader ? <CircularProgress size={24} color="secondary" /> : 'Decline'}
                </StyledButton>
                <StyledButton variant='contained' size='small' className={styles['explore-button']} onClick={() => { requestTransfer('approve') } } disabled={approveLoader || declineLoader}>
                  {approveLoader ? <CircularProgress size={24} color="secondary" /> : 'Approve'}
                </StyledButton></>
                : null
            }
          </Stack>
        </Stack></>
        : 'No Pending Requests'
      }
    </div>
  )
}

export default PendingCell
