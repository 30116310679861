/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {Box, Button, CircularProgress, Divider, Grid, Snackbar, Stack, TextField, Typography, useMediaQuery, useTheme, FormHelperText } from '@mui/material'
import styles from './admin.module.scss'
import { useAccount } from 'wagmi'
import Error404 from '../404/Error404'
import { adminAddress, convertBigNumberToDate, devAddress, isWalletAddress } from '../../utils/common'
import { ethers } from 'ethers'
import { abiWarehouse, contractaddressWarehouse } from "../../utils/common"
import PendingCell from './components/PendingCell'
import { RootStateType } from '../../redux/interface'
import { useSelector } from 'react-redux'

function AdminSettings() {
  const accountDetails = useAccount()
  const connectedAddress: any = accountDetails?.address
  const [loader, setLoader] = useState(false)
  const [approveLoader, setApproveLoader] = useState(false)
  const [declineLoader, setDeclineLoader] = useState(false)

  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  const [notification, setNotification] = React.useState({
    message: '',
    open: false,
  })
  const [address, setAddress] = React.useState<any>('')
  const [transferRequest, setTransferRequest] = useState<any>()
  const [transferRequestTime, setTransferRequestTime] = useState<any>()

  const validaterAddress: any = useSelector((state: RootStateType) => state.auth.validatorAddress)

  const isFormValid = () => {
    return !loader && isWalletAddress(address) && connectedAddress === adminAddress
  }

  const handleFormChange = (event: any) => {
    const { value } = event.target
    setAddress(value)
  }

  const requestValidatorUpdate = async(key: any) => {
    const connectedAddress = accountDetails?.address
    if (!connectedAddress) {
      setLoader(false)
      alert("Oops! It seems the wallet is not connected. Please connect your wallet to proceed.")
    }
    if (window.ethereum && connectedAddress) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(contractaddressWarehouse, abiWarehouse, signer)

        if (key === 'request') {
          setLoader(true)
          const tx = await nftContract.requestValidatorUpdate(address)
          await tx.wait()
          await getValidatorUpdateRequests()
          setAddress('')
          console.log('Validator Update tx', tx)
        } else if (key === 'approve') {
          setApproveLoader(true)
          const tx = await nftContract.approveValidatorUpdate()
          await tx.wait()
          setTransferRequest(undefined)
          console.log('approve tx', tx)
        } else {
          setDeclineLoader(true)
          const tx = await nftContract.declineValidatorUpdate()
          await tx.wait()
          setTransferRequest(undefined)
          console.log('decline tx', tx)
        }
        setLoader(false)
        setApproveLoader(false)
        setDeclineLoader(false)
        setNotification({
          message: key === 'request' ? 'Success! Validator Update Request Generated.' : key === 'approve' ? 'Success! Validator Update Request Approved.' : 'Success! Validator Update Request Decline.',
          open: true
        })
      } catch (error: any) {
        console.log(error)
        setLoader(false)
        setApproveLoader(false)
        setDeclineLoader(false)
        if (error?.data?.code === -32000) {
          setNotification({
            message: 'Insufficient funds for transactions',
            open: true
          })
        } else {
          setNotification({
            message: 'Something went wrong',
            open: true
          })
        }
      }
    }
  }

  const getValidatorUpdateRequests = async() => {
    const connectedAddress = accountDetails?.address
    if (!connectedAddress) {
      alert("Oops! It seems the wallet is not connected. Please connect your wallet to proceed.")
    }
    if (window.ethereum && connectedAddress) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(contractaddressWarehouse, abiWarehouse, signer)

        const tx = await nftContract.validatorUpdateRequest()
        const dateStamp = convertBigNumberToDate(tx.creationTime)
        setTransferRequestTime(dateStamp)
        if (dateStamp !== null) {
          setTransferRequest(tx)
        } else {
          setTransferRequest(null)
        }
      } catch (error: any) {
        console.log(error)
        if (error?.data?.code === -32000) {
          setNotification({
            message: 'Insufficient funds for transactions',
            open: true
          })
        } else {
          setNotification({
            message: 'Something went wrongg',
            open: true
          })
        }
      }
    }
  }

  React.useEffect(() => {
    if (accountDetails.address) {
      getValidatorUpdateRequests()
    }
  }, [accountDetails.address])

  return (
    accountDetails?.address && (accountDetails?.address === adminAddress || accountDetails?.address === validaterAddress || accountDetails?.address === devAddress)
      ? <>
        <Box className={styles['home-layout']}>
          <main>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h5' fontWeight={700}>Update Validator</Typography>
              {/* <Button variant='contained' size='medium' className={styles['save']}>Save</Button> */}
            </Stack>
            <Grid container spacing={1}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={styles['settings-cell']}>
                  <Stack direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} mt={1} gap={2}>
                    <Typography variant='body1'>New Validator Address</Typography>
                    <Stack direction={'column'} gap={2} width={'100%'}>
                      <TextField
                        name='address'
                        variant='outlined'
                        className='settings-input'
                        size='small'
                        placeholder='Enter Address'
                        value={address}
                        onChange={handleFormChange}
                        fullWidth
                      />
                    </Stack>
                  </Stack>
                </div>
              </Grid>
            </Grid>
            <Stack justifyContent={'center'} alignItems={'center'} mt={1} mb={4} width={'100%'}>
              <Button sx={{minWidth: '250px'}} fullWidth={breakpoint} onClick={() => requestValidatorUpdate('request')} disabled={!isFormValid()} variant='contained' size='large'>
                {loader ? <CircularProgress size={24} color="secondary" /> : 'Update Validator'}
              </Button>
            </Stack>
            <Divider />
            <Stack direction={'row'} mt={3} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h5' fontWeight={700}>Pending Requests</Typography>
            </Stack>
            <Stack gap={'10px'} mt={2}>
              <PendingCell requestTransfer={requestValidatorUpdate} transferRequestData={transferRequest} transferRequestTime={transferRequestTime} approveLoader={approveLoader} declineLoader={declineLoader}/>
            </Stack>
          </main>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={3000}
            open={notification.open}
            message={notification.message}
            onClose={() => {
              setNotification({
                message: '',
                open: false
              })
            }}
          />
        </Box>
      </>
      : <Error404 />
  )
}

export default AdminSettings
