
export const GET_USER = 'GET_USER'
export const GET_VALIDATOR = 'GET_VALIDATOR'

export interface user {
  walletId: string,
}

interface GetUserAction {
  type: typeof GET_USER;
  payload: user
}

interface GetValidatorAction {
  type: typeof GET_VALIDATOR;
  payload: any
}

export type AuthActionTypes = GetUserAction | GetValidatorAction;

export async function getUser(address: any) {
  const userData = {walletId: address}
  return {type: GET_USER, payload: userData}
}

export async function getValidator(address: any) {
  return {type: GET_VALIDATOR, payload: address}
}

export async function updateWalletId(id: any) {
  const address = {walletId: id}
  return {type: GET_USER, payload: address}
}
