import { createTheme } from '@mui/material/styles'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#366778',
    },
    mode: 'light',
    secondary: {
      main: '#383838',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: ''
    },
  },
  typography: {
    fontFamily: ["Space Grotesk", "sans-serif"].join(","),
    allVariants: {
      letterSpacing: 0.7,
    },
  },
})

export default theme
