import NFTListing from './NFTListing'
import Layout from '../../components/Layout/Layout'

const nftRoutes = [
  {
    path: '/collection',
    name: 'Collections',
    element: <Layout><NFTListing /></Layout>,
  },
]

export default nftRoutes
