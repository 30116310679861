/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import ImageCard from './component/ImageCard'
import {Box, Stack, Typography } from '@mui/material'
import styles from './nft.module.scss'
import { Alchemy, Network } from "alchemy-sdk"
import { useAccount } from 'wagmi'
import { contractaddress } from "../../utils/common"
import TransferDialog from '../../components/Dialogs/TransferDialog'
import { StyledButton } from '../../components/StyledComponents/StyledComponents'
import NoNft from '../../assets/images/no-nft.png'
import WalletImage from '../../assets/images/connect_wallet_logo.svg'
import { useSelector } from 'react-redux'
import { RootStateType } from '../../redux/interface'

function NFTListing() {
  const [images, setList] = React.useState<any>([])
  const [transferModal, setTransferModal] = React.useState<any>(false)
  const [selectedIds, setSelectedIds] = React.useState<any>([])
  const accountDetails = useAccount()
  const wallet_address = useSelector((state: RootStateType) => state.auth.user.walletId)
  const alchemy = new Alchemy({
    apiKey: process.env.REACT_APP_ALCHEMY_KEY,
    network: Network.MATIC_MAINNET
  })

  const fetchNft = async() => {
    if (accountDetails.address && contractaddress) {
      const ownedNFTs = await alchemy.nft.getNftsForOwner(accountDetails.address, {
        contractAddresses: [contractaddress]
      })
      console.log('ownedNFTs', ownedNFTs)
      if (ownedNFTs) {
        setList(ownedNFTs?.ownedNfts)
      }
    }
  }

  React.useEffect(() => {
    setSelectedIds([])
    if (accountDetails.address) {
      fetchNft()
    }
  }, [accountDetails.address])

  return (
    <Box className={styles['home-layout']}>
      <main>
        {/* <Stack className={styles['notif']} justifyContent={'space-between'} alignItems={'center'} width={'100%'} maxWidth={'750px'}>
          <Typography>You have an unclaimed NFT</Typography>
          <StyledButton disabled size='small' className={styles['claim-btn']} onClick={() => { whitelistAddress() }} variant='outlined'>Claim</StyledButton>
        </Stack> */}
        <Stack className={styles['content']}>
          {
            wallet_address === ''
              ? <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} className={styles['empty-controller']}>
                <img src={WalletImage} width={100} />
                <Typography mt={2} variant='h5' fontWeight={600} textTransform={'uppercase'}>Wallet not connected</Typography>
                {/* <Typography variant='body1'>Click on the wallet icon on the header to get started</Typography> */}
                <Typography variant='body1'>Please connect your wallet to access this page</Typography>
              </Stack>
              : images.length
                ? <ImageCard images={images} setSelectedIds={setSelectedIds} selectedIds={selectedIds} />
                : <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} className={styles['empty-controller']}>
                  <img src={NoNft} width={100} />
                  <Typography variant='h5' fontWeight={600}>No Added NFTs</Typography>
                  <Typography variant='body1'>Purchase some NFTs to get started</Typography>
                </Stack>
          }
        </Stack>
        <Stack direction={'row'} className={styles['explore-button']} gap={2}>
          <StyledButton disabled={!selectedIds.length} variant='contained' onClick={() => { setTransferModal(true) }} >Transfer</StyledButton>
        </Stack>
      </main>
      <TransferDialog open={transferModal} handleClose={() => setTransferModal(false)} selectedIds={selectedIds} fetchNft={fetchNft} setSelectedIds={setSelectedIds} />
    </Box>
  )
}

export default NFTListing
