
import React, { useState, useEffect } from 'react'
import styles from './AppHeader.module.scss'
import { Box, Fade, IconButton, Modal, Stack, useMediaQuery, Typography} from '@mui/material'
import Logo from '../../assets/images/mprofy-log.png'
import MenuComponent from './MenuComponent'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import LeftMenu from './LeftMenu'
import { getAllRoutes } from '../../routes/router'
import { getUser, getValidator } from '../../redux/actions/AuthActions'
import { useDispatch } from 'react-redux'
import { abiWarehouse, contractaddressWarehouse } from '../../utils/common'
import { ethers } from 'ethers'

function AppHeader() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:1200px)')
  const [openMenu, setOpen] = useState(false)
  const accountDetails = useAccount()
  const [connectedAccount, setConnectedAccount]: any = useState('')
  const { open } = useWeb3Modal()
  const routes: any = getAllRoutes()
  const location = useLocation()

  function returnHeaderLabel() {
    const currentPath = location.pathname

    for (var i = 0; i < routes.length - 1; i++) {
      if (currentPath === routes[i].path) {
        return routes[i]?.name
      }
    }
  }

  const updateWalletAddress = async(address: any) => {
    dispatch(await getUser(address))
  }

  const getValidatorUpdateRequests = async() => {
    const connectedAddress = accountDetails?.address
    if (!connectedAddress) {
      alert("Oops! It seems the wallet is not connected. Please connect your wallet to proceed.")
    }
    if (window.ethereum && connectedAddress) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(contractaddressWarehouse, abiWarehouse, signer)

        const tx = await nftContract.validator()
        await dispatch(await getValidator(tx))
      } catch (error: any) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (accountDetails?.address) {
      getValidatorUpdateRequests()
      setConnectedAccount(accountDetails?.address)
      updateWalletAddress(accountDetails?.address)
    } else {
      setConnectedAccount('')
      updateWalletAddress('')
    }
  }, [accountDetails])

  return (
    <Box className={'off-white-container'}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={styles['header-layout']}>
        <Stack direction={isMobile ? 'row-reverse' : 'row'} justifyContent={isMobile ? 'space-between' : 'flex-start'} alignItems={'center'} gap={isMobile ? '10px' : '16px'} width={'100%'}>
          <IconButton className={styles['menu-icon']}>
            <LeftMenu connectedAccount={connectedAccount} connectWithMetamask={() => { open() }} />
          </IconButton>
          <Stack direction={'row'} gap={'16px'} className={styles['menu-logo']} alignItems={'center'}>
            <img src={Logo} width={50} height={45} alt="Logo" onClick={() => { navigate('/') }} />
            <Typography variant={isMobile ? 'body1' : 'h5'} fontWeight={700}>{returnHeaderLabel()}</Typography>
          </Stack>
        </Stack>
        {
          !isMobile &&
          <MenuComponent style='row' connectWithMetamask={() => { open() }} connectedAccount={connectedAccount} closeMenu={() => { setOpen(false) }} />
        }
      </Stack>
      <Modal
        open={openMenu}
        onClose={() => { setOpen(e => !e) }}
      >
        <Fade in={openMenu}>
          <div className={styles['mobile-menu']}>
            <div className={styles['background-selector']} onClick={() => { setOpen(e => !e) }}></div>
            <IconButton onClick={() => { setOpen(e => !e) }} sx={{ position: 'relative'}}>
              <CloseIcon sx={{ fontSize: '30px'}} />
            </IconButton>
            <MenuComponent style='column' connectWithMetamask={() => { open() }} connectedAccount={connectedAccount} closeMenu={() => { setOpen(false) }} />
          </div>
        </Fade>
      </Modal>
    </Box>
  )
}

export default AppHeader
