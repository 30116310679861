import React from 'react'
import {
  Typography,
  Grid,
  ButtonBase,
  useMediaQuery
} from '@mui/material'
import styles from '../nft.module.scss'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

interface ImageGalleryProps {
  images: any,
  setSelectedIds: any,
  selectedIds: any
}

const ImageCard: React.FC<ImageGalleryProps> = ({ images, setSelectedIds, selectedIds }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const toggleSelection = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId: any) => selectedId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }
  return (
    <Grid container spacing={isMobile ? 2 : 3} className={styles['grid']}>
      {images.map((image: any, index: any) => (
        <Grid item xs={6} sm={4} md={4} key={index} className={styles['cell']}>
          <ButtonBase className={`${styles['imageCard']} ${styles['active-cell']}`} onClick={() => {
            toggleSelection(image?.tokenId)
          }}>
            <div className={styles['check-mark']}>
              {selectedIds.includes(image?.tokenId) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
            </div>
            <img draggable={false} src={image?.rawMetadata?.image} alt={image.alt} className={styles['image']} />
            {image && (
              <Typography variant="caption" className={styles['caption']}>
                {"Genesis #" + (image?.tokenId)}
              </Typography>
            )}
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  )
}

export default ImageCard
