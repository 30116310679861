/* eslint-disable no-unused-vars */
import React from 'react'
import styles from '../admin.module.scss'
import { CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { StyledButton } from '../../../components/StyledComponents/StyledComponents'
import EastIcon from '@mui/icons-material/East'
import WestIcon from '@mui/icons-material/West'
import ImageIcon from '@mui/icons-material/Image'
import { contractaddressWarehouse } from "../../../utils/common"
import { useAccount } from 'wagmi'
const { BigNumber } = require('ethers')

function PendingCell({requestTransfer, transferRequestData, transferRequestTime, selectedIds, approveLoader, declineLoader}: any) {
  const theme = useTheme()
  const accountDetails = useAccount()
  const connectedAddress = accountDetails?.address
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))
  const tokenIds = transferRequestData
    ? transferRequestData.args.tokenIds.map((item: any) => {
      const bigNumber = BigNumber.from(item._hex) // Use BigNumber.from()
      return bigNumber.toString() // Or other conversion methods
    })
    : null
  const tokenCount = tokenIds?.length || 0
  return (
    <div className={styles['pending-cell']}>
      {transferRequestData
        ? <><Stack className={styles['content']}>
          <div className={styles['pending-attr']}>
            <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
              <WestIcon />
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <span className={styles['label']}>From</span>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Typography>{contractaddressWarehouse?.replace(/^(0x[a-fA-F\d]{4}).*(\w{3})$/, '$1..$2')}</Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
              <EastIcon />
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <span className={styles['label']}>To</span>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Typography>{transferRequestData?.args?.receiver?.replace(/^(0x[a-fA-F\d]{4}).*(\w{3})$/, '$1..$2')}</Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction={'row'} gap={'10px'} alignItems={'center'} width={'100%'}>
              <ImageIcon />
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <span className={styles['label']}>Tokens</span>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Typography>{tokenCount > 1 ? `#${tokenIds?.[0]} to #${tokenIds?.[tokenCount - 1]}` : `#${tokenIds?.[0]}`}</Typography>
                </Grid>
              </Grid>
            </Stack>
          </div>
          <Stack alignItems={breakpoint ? 'flex-start' : 'flex-end'} gap={'4px'}>
            <Typography variant='body1' fontSize={'12px'} fontWeight={700}>Quantity</Typography>
            <Typography variant='h5' fontWeight={700}>{tokenCount}</Typography>
          </Stack>
        </Stack><Stack className={styles['footer']}>
          <Typography variant="body1" color="grey">Created on {transferRequestTime}</Typography>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} width={breakpoint ? '100%' : '50%'} gap={2}>
            {connectedAddress === transferRequestData?.args?.actor
              ? <StyledButton variant='text' size='small' className={styles['explore-button']} disabled>Transfer Request Pending</StyledButton>
              : <><StyledButton variant='text' size='small' className={styles['explore-button']} onClick={() => { requestTransfer('decline') } } disabled={declineLoader || approveLoader}>
                {declineLoader ? <CircularProgress size={24} color="secondary" /> : 'Decline'}
              </StyledButton>
              <StyledButton variant='contained' size='small' className={styles['explore-button']} onClick={() => { requestTransfer('approve') } } disabled={approveLoader || declineLoader}>
                {approveLoader ? <CircularProgress size={24} color="secondary" /> : 'Approve'}
              </StyledButton></>
            }
          </Stack>
        </Stack></>
        : 'No Pending Requests'
      }
    </div>
  )
}

export default PendingCell
