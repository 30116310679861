import HomeIcon from '@mui/icons-material/Home'
import { ReactElement } from 'react'
import CollectionsIcon from '@mui/icons-material/Collections'
// import ApartmentIcon from '@mui/icons-material/Apartment'
// import ApartmentIcon from '@mui/icons-material/Apartment'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
// import LoupeIcon from '@mui/icons-material/Loupe'

type MenuProps = {
  label: string,
  link: string,
  available: boolean,
  icon: ReactElement
}

export const AdminItems: MenuProps[] = [
  {
    label: 'Home',
    link: '/',
    available: true,
    icon: <HomeIcon />
  },
  // {
  //   label: 'Mint NFT',
  //   link: '/mint-nft',
  //   available: true,
  //   icon: <LoupeIcon />
  // },
  {
    label: 'My Collection',
    link: '/collection',
    available: true,
    icon: <CollectionsIcon />

  },
  {
    label: 'Admin Bulk Transfer',
    link: '/admin/transfer',
    available: true,
    icon: <AdminPanelSettingsIcon />
  },
  {
    label: 'Admin Settings ',
    link: '/admin/settings',
    available: true,
    icon: <AdminPanelSettingsIcon />
  },
]

export const MenuItems: MenuProps[] = [
  {
    label: 'Home',
    link: '/',
    available: true,
    icon: <HomeIcon />
  },
  // {
  //   label: 'Mint NFT',
  //   link: '/mint-nft',
  //   available: true,
  //   icon: <LoupeIcon />
  // },
  {
    label: 'My Collection',
    link: '/collection',
    available: true,
    icon: <CollectionsIcon />

  },
]

