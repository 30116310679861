import AdminSettings from './AdminSettings'
import Layout from '../../components/Layout/Layout'

const adminSettingRoutes = [
  {
    path: '/admin/settings',
    name: 'Admin',
    element: <Layout><AdminSettings /></Layout>,
  },
]

export default adminSettingRoutes
