import { AuthActionTypes, GET_USER, user, GET_VALIDATOR } from '../actions/AuthActions'
// Define the initial state of the application
export const initialState = {
  user: {
    walletId: '',
  },
  validatorAddress: '',
}

// Define the shape of the state
export interface AppState {
  user: user
  validatorAddress: any
}

// Define the reducer function
export function AuthReducer(state = initialState, action: AuthActionTypes): AppState {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case GET_VALIDATOR:
      return {
        ...state,
        validatorAddress: action.payload,
      }
    default:
      return state
  }
}
