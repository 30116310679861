/* eslint-disable no-unused-vars */
import {Button, Popover, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect} from 'react'
import styles from './AppHeader.module.scss'
import { Box } from '@mui/system'
import logo from '../../assets/images/connect_wallet_logo.png'

declare global {
  interface Window {
    ethereum?: any;
  }
}

type MenuProps = {
  style: 'row' | 'column',
  connectedAccount: any,
  connectWithMetamask: any,
  closeMenu: () => void
}

function MenuComponent({ connectedAccount, connectWithMetamask }: MenuProps) {
  var reg = /\b(\w{5})\w+(\w{5})\b/g
  return (
    <Stack>
      {connectedAccount
        ? <Button size='large' onClick={connectWithMetamask} className={styles['header-button']}>
          <img src={logo} draggable={false} style={{height: '32px', width: '32px'}}></img>
          <Typography fontSize={'14px'} fontWeight={'800'}>{connectedAccount.replace(reg, '$1---$2')}</Typography>
        </Button>
        : <Button size='large' onClick={connectWithMetamask} className={styles['header-button']}>
          <img src={logo} draggable={false} style={{height: '32px', width: '32px'}}></img>
          <Typography fontSize={'14px'} fontWeight={'800'}>Not Connected</Typography>
        </Button>
      }
    </Stack>
  )
}

export default MenuComponent
