/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {Box, Button, CircularProgress, Divider, Grid, Snackbar, Select, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme, FormHelperText } from '@mui/material'
import styles from './admin.module.scss'
import TierModal from './components/TierModal'
import { useAccount } from 'wagmi'
import Error404 from '../404/Error404'
import { adminAddress, convertBigNumberToDate, devAddress, isWalletAddress } from '../../utils/common'
import { ethers } from 'ethers'
import { abi, contractaddress, abiWarehouse, contractaddressWarehouse } from "../../utils/common"
import PendingCell from './components/PendingCell'
import { Alchemy, Network } from "alchemy-sdk"
import { useSelector } from 'react-redux'
import { RootStateType } from '../../redux/interface'

function Admin() {
  const accountDetails = useAccount()
  const [loader, setLoader] = useState(false)
  const [approveLoader, setApproveLoader] = useState(false)
  const [declineLoader, setDeclineLoader] = useState(false)

  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))
  const alchemy = new Alchemy({
    apiKey: process.env.REACT_APP_ALCHEMY_KEY,
    network: Network.MATIC_MAINNET
  })

  const [notification, setNotification] = React.useState({
    message: '',
    open: false,
  })
  const [selectedIds, setSelectedIds] = React.useState<any>([])
  const [formData, setFormData] = useState({
    count: 0,
    from: '',
    to: '',
    address: '',
  })
  const [transferRequest, setTransferRequest] = useState<any>()
  const [transferRequestTime, setTransferRequestTime] = useState<any>()
  const [nftIds, setNftIds] = React.useState<any>([])

  const validaterAddress: any = useSelector((state: RootStateType) => state.auth.validatorAddress)

  const [error, setError] = React.useState<any>(false)

  const isFormValid = () => {
    return !loader && isWalletAddress(formData.address) && formData.count > 0
  }

  const handleFormChange = (event: any) => {
    setError(false)
    const { name, value } = event.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
    // Perform the check when both 'from' and 'to' are updated
    if (name === 'from' || name === 'to') {
      const fromValue = name === 'from' ? value : formData.from
      const toValue = name === 'to' ? value : formData.to

      // Check if 'from' is lower than 'to'
      if (fromValue && toValue && parseInt(fromValue, 10) >= parseInt(toValue, 10)) {
        // Handle the error or provide feedback to the user
        setError(true)
      }
    }
  }

  const requestTransfer = async(key: any) => {
    const connectedAddress = accountDetails?.address
    if (!connectedAddress) {
      setLoader(false)
      alert("Oops! It seems the wallet is not connected. Please connect your wallet to proceed.")
    }
    if (window.ethereum && connectedAddress) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(contractaddressWarehouse, abiWarehouse, signer)

        if (key === 'request') {
          setLoader(true)
          const tx = await nftContract.requestBulkTransfer(selectedIds, formData.address)
          await tx.wait()
          await getTransferRequests()
          setFormData({
            count: 0,
            from: '',
            to: '',
            address: '',
          })
          console.log('Bulk tx', tx)
        } else if (key === 'approve') {
          setApproveLoader(true)
          const tx = await nftContract.approveBulkTransfer()
          await tx.wait()
          setTransferRequest(undefined)
          console.log('approve tx', tx)
        } else {
          setDeclineLoader(true)
          const tx = await nftContract.declineBulkTransfer()
          await tx.wait()
          setTransferRequest(undefined)
          console.log('decline tx', tx)
        }
        setLoader(false)
        setApproveLoader(false)
        setDeclineLoader(false)
        setNotification({
          message: key === 'request' ? 'Success! Transfer Request Generated.' : key === 'approve' ? 'Success! Transfer Request Approved.' : 'Success! Transfer Request Decline.',
          open: true
        })
      } catch (error: any) {
        console.log(error)
        setLoader(false)
        setApproveLoader(false)
        setDeclineLoader(false)
        if (error?.data?.code === -32000) {
          setNotification({
            message: 'Insufficient funds for transactions',
            open: true
          })
        } else {
          setNotification({
            message: 'Something went wrong',
            open: true
          })
        }
      }
    }
  }

  const fetchOwnedNftList = (data: any) => {
    if (data && data.length > 0) {
      const tokenValuesArray: any = []

      data.forEach((nft: any) => {
        // Check if attributesArray exists and has at least two elements
        const tokenId = nft?.tokenId
        // Check if tokenId exists
        if (tokenId) {
          tokenValuesArray.push(tokenId)
        }
      })
      setNftIds(tokenValuesArray)
    } else {
      console.log("The ownedNfts array is empty or undefined.")
    }
  }

  const fetchNft = async() => {
    if (accountDetails.address && contractaddress) {
      const ownedNFTs = await alchemy.nft.getNftsForOwner(contractaddressWarehouse, {
        contractAddresses: [contractaddress]
      })
      if (ownedNFTs) {
        fetchOwnedNftList(ownedNFTs?.ownedNfts)
        // setList(ownedNFTs?.ownedNfts)
      }
    }
  }

  const getTransferRequests = async() => {
    const connectedAddress = accountDetails?.address
    if (!connectedAddress) {
      alert("Oops! It seems the wallet is not connected. Please connect your wallet to proceed.")
    }
    if (window.ethereum && connectedAddress) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(contractaddressWarehouse, abiWarehouse, signer)

        const tx = await nftContract.bulkTransferRequest()
        const dateStamp = convertBigNumberToDate(tx.creationTime)
        if (dateStamp !== null) {
          setTransferRequestTime(dateStamp)
          const filter = nftContract.filters.BulkTransferRequested()

          // Get the past events
          nftContract.queryFilter(filter, 0, 'latest').then((events: any) => {
            // console.log('events', events)
            setTransferRequest(events[events.length - 1])
          })
        }
      } catch (error: any) {
        console.log(error)
        if (error?.data?.code === -32000) {
          setNotification({
            message: 'Insufficient funds for transactions',
            open: true
          })
        } else {
          setNotification({
            message: 'Something went wrongg',
            open: true
          })
        }
      }
    }
  }

  React.useEffect(() => {
    if (accountDetails.address) {
      fetchNft()
      getTransferRequests()
    }
  }, [accountDetails.address])

  React.useEffect(() => {
    if (accountDetails.address && formData.from && formData.to) {
      const filteredIds = nftIds.filter((id: any) => {
        const numericId = parseInt(id) // Convert string to number
        return numericId >= parseInt(formData.from) && numericId <= parseInt(formData.to)
      })
      setSelectedIds(filteredIds)
      setFormData((prevData) => ({
        ...prevData,
        count: filteredIds?.length,
      }))
    }
  }, [formData])

  return (
    accountDetails?.address && (accountDetails?.address === adminAddress || accountDetails?.address === validaterAddress || accountDetails?.address === devAddress)
      ? <>
        <Box className={styles['home-layout']}>
          <main>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h5' fontWeight={700}>  Bulk Transfer</Typography>
              {/* <Button variant='contained' size='medium' className={styles['save']}>Save</Button> */}
            </Stack>
            <Grid container spacing={1}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={styles['settings-cell']}>
                  <Stack direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} mt={1} gap={2}>
                    <Typography variant='body1'>From</Typography>
                    <Stack direction={'column'} gap={2} width={'100%'}>
                      <TextField
                        name='from'
                        variant='outlined'
                        className='settings-input'
                        size='small'
                        placeholder='Token Id #'
                        onChange={handleFormChange}
                        fullWidth
                      />
                    </Stack>
                  </Stack>
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={styles['settings-cell']}>
                  <Stack direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} mt={1} gap={2}>
                    <Typography variant='body1'>To</Typography>
                    <Stack direction={'column'} gap={0} width={'100%'}>
                      <TextField
                        name='to'
                        variant='outlined'
                        className='settings-input'
                        size='small'
                        placeholder='Token Id #'
                        disabled={!formData.from}
                        onChange={handleFormChange}
                        fullWidth
                      />
                      {error && <FormHelperText sx={{color: 'red', margin: 0, padding: 0}}>{"'To' should be greter than 'From'"}</FormHelperText>}
                    </Stack>
                  </Stack>
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={styles['settings-cell']}>
                  <Stack direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} mt={1} gap={2}>
                    <Typography variant='body1'>Receiver Address</Typography>
                    <Stack direction={'column'} gap={2} width={'100%'}>
                      <TextField
                        name='address'
                        variant='outlined'
                        className='settings-input'
                        size='small'
                        placeholder='Receiver Address'
                        value={formData.address}
                        onChange={handleFormChange}
                        fullWidth
                      />
                    </Stack>
                  </Stack>
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={styles['settings-cell']}>
                  <Stack direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} mt={1} gap={2}>
                    <Typography variant='body1'>Quantity</Typography>
                    <Stack direction={'column'} gap={2} width={'100%'}>
                      <TextField
                        name='count'
                        disabled
                        variant='outlined'
                        className='settings-input'
                        size='small'
                        placeholder='Tier'
                        value={formData.count}
                        onChange={handleFormChange}
                        fullWidth
                      />
                    </Stack>
                  </Stack>
                </div>
              </Grid>
            </Grid>
            <Stack justifyContent={'center'} alignItems={'center'} mt={1} mb={4} width={'100%'}>
              <Button sx={{minWidth: '250px'}} fullWidth={breakpoint} onClick={() => requestTransfer('request')} disabled={!isFormValid()} variant='contained' size='large'>
                {loader ? <CircularProgress size={24} color="secondary" /> : 'Request Transfer'}
              </Button>
            </Stack>
            <Divider />
            <Stack direction={'row'} mt={3} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h5' fontWeight={700}>Pending Requests</Typography>
            </Stack>
            <Stack gap={'10px'} mt={2}>
              <PendingCell requestTransfer={requestTransfer} transferRequestData={transferRequest} transferRequestTime={transferRequestTime} selectedIds={selectedIds} approveLoader={approveLoader} declineLoader={declineLoader}/>
              {/* <PendingCell /> */}
            </Stack>
          </main>
          {/* <TierModal open={showTierModal} toggleOpen={() => setShowTierModal(e => !e)} submit={Mint} /> */}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={3000}
            open={notification.open}
            message={notification.message}
            onClose={() => {
              setNotification({
                message: '',
                open: false
              })
            }}
          />
        </Box>
      </>
      : <Error404 />
  )
}

export default Admin
